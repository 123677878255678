import "jquery.marquee";
import "../sass/main.scss";
import LazyLoad from "vanilla-lazyload";
import globalSearch from "./global-search";
import homeSliders from "./modules/sliders";
import {
  partenairesExtranetSlider,
  nouveauArrivantsSlider,
  publicationsSliderExtranetAgent,
  contactUtilesSlider,
} from "./modules/sliders";
import mainMenu from "./modules/main-menu";
import { eventFire } from "./modules/helpers";

jQuery(function () {
  console.log("home.js");
  var lazyLoad = new LazyLoad();
  globalSearch();
  mainMenu();
  homeSliders();

  var sliderExtranetPartenaires = document.getElementById(
    "containerSliderPartenaires"
  );
  if (sliderExtranetPartenaires !== null) partenairesExtranetSlider();

  var sliderUsers = document.getElementById("sliderUsers");
  if (sliderUsers !== null) nouveauArrivantsSlider();

  var publicationsSlider = document.getElementById("publicationsSlider");
  if (publicationsSlider !== null) publicationsSliderExtranetAgent();

  var sliderContactsUtiles = document.getElementById("sliderContactsUtiles");
  if (sliderContactsUtiles !== null) contactUtilesSlider();

  // Optimalisation: Store the references outside the event handler:
  var $window = jQuery(window);
  var $pane = jQuery("#pane1");

  function checkWidth() {
    var windowsize = $window.width();
    console.log(windowsize);
    if (windowsize < 600) {
      jQuery(".flash-infos__marquee").marquee({
        duration: 10000,
        gap: 0,
        delayBeforeStart: 0,
        direction: "left",
        duplicated: false,
        pauseOnHover: true,
        startVisible: true,
      });
    } else  {
      jQuery(".flash-infos__marquee").marquee({
        duration: 15000,
        gap: 0,
        delayBeforeStart: 0,
        direction: "left",
        duplicated: false,
        pauseOnHover: true,
        startVisible: true,
      });
    }
  }

  // Execute on load
  checkWidth();
  // Bind event listener
  jQuery(window).resize(checkWidth);

  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });
});
