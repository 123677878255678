import MicroModal from "micromodal";
import LazyLoad from "vanilla-lazyload";
import { Tabs } from "./utils/tabs";

export default function globalSearch() {
  MicroModal.init({
    disableScroll: true,
  });
  var ajaxSearch = function () {
    var searchTerm = jQuery("#search-input").val();
    jQuery.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "global_search",
        search_term: searchTerm,
      },
      beforeSend: function () {
        jQuery("#search-results").empty();
        jQuery(".loader").show();
      },
      success: function (data) {
        jQuery(".loader").hide();
        jQuery("#search-results").html(data);
        const lazyLoad = new LazyLoad();
        const tabs = new Tabs({
          elem: "tabs",
          open: 0,
        });

        console.log(tabs);
      },
    });
    return false;
  };

  let timer = null;
  jQuery("#search-input").keyup(function (e) {
    clearTimeout(timer);
    timer = setTimeout(ajaxSearch, 500);
  });

  jQuery("#modal-search").on("click", ajaxSearch);
}
