import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";

export function partenairesExtranetSlider() {
  var lazyLoad = new LazyLoad();

  function sliderLazy() {
    lazyLoad.update();
  }
  if (jQuery("#containerSliderPartenaires").length != 0) {
    var slider = tns({
      container: "#containerSliderPartenaires",
      items: 6,
      // slideBy: 'page',
      loop: true,
      nav: false,
      controls: true,
      controlsContainer: ".partenaires-controllers",
      responsive: {
        320: {
          items: 3,
        },
        500: {
          items: 3,
        },
        768: {
          items: 6,
        },
        1280: {
          items: 6,
        },
        1440: {
          items: 6,
        },
        1700: {
          items: 6,
        },
      },
    });
    slider.events.on("transitionEnd", sliderLazy);
  }
}

export function nouveauArrivantsSlider() {
  var lazyLoad = new LazyLoad();

  function sliderLazy() {
    lazyLoad.update();
  }
  if (jQuery("#sliderUsers").length != 0) {
    var slider = tns({
      container: "#sliderUsers",
      items: 1,
      slideBy: "page",
      controls: false,
      loop: true,
      nav: true,
    });
    slider.events.on("transitionEnd", sliderLazy);
  }
}

export function contactUtilesSlider() {
  var lazyLoad = new LazyLoad();

  function sliderLazy() {
    lazyLoad.update();
  }
  if (jQuery("#sliderContactsUtiles").length != 0) {
    if (jQuery(".home-contact-extranet-elus-noevent").length != 0) {
      var slider = tns({
        container: "#sliderContactsUtiles",
        items: 1,
        // slideBy: "page",
        controls: true,
        loop: true,
        nav: false,
        gutter: 40,
        controlsContainer: ".controllers-slider-contacts",
        responsive: {
          580: {
            items: 2,
          },
          960: {
            items: 2,
          },
          1280: {
            items: 4,
          },
          1440: {
            items: 4,
          },
        },
      });
      slider.events.on("transitionEnd", sliderLazy);
    } else {
      var slider = tns({
        container: "#sliderContactsUtiles",
        items: 1,
        // slideBy: "page",
        controls: true,
        loop: true,
        nav: false,
        gutter: 20,
        controlsContainer: ".controllers-slider-contacts",
        responsive: {
          580: {
            items: 2,
          },
          960: {
            items: 3,
          },
          1230: {
            items: 4,
          },
          1250: {
            items: 2,
            gutter: 50,
          },
        },
      });
      slider.events.on("transitionEnd", sliderLazy);
    }
  }
}

export function publicationsSliderExtranetAgent() {
  var lazyLoad = new LazyLoad();

  function sliderLazy() {
    lazyLoad.update();
  }
  if (jQuery("#publicationsSlider").length != 0) {
    var slider = tns({
      container: "#publicationsSlider",
      items: 5,
      // slideBy: "page",
      controls: true,
      loop: true,
      nav: false,
      controlsContainer: ".controllers-publications-elus",
      responsive: {
        320: {
          items: 1,
        },
        500: {
          items: 1,
        },
        768: {
          items: 2,
        },
        1280: {
          items: 5,
        },
        1440: {
          items: 5,
        },
        1700: {
          items: 5,
        },
      },
    });
    slider.events.on("transitionEnd", sliderLazy);
  }
}

export default function homeSliders() {
  var lazyLoad = new LazyLoad();

  function sliderLazy() {
    lazyLoad.update();
  }

  if (jQuery(".header__slider").length != 0) {
    var slider_header = tns({
      container: ".header__slider",
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      nav: true,
    });
    slider_header.events.on("transitionEnd", sliderLazy);
  }
  if (jQuery(".produits__slider").length != 0) {
    var slider_produits = tns({
      container: ".produits__slider",
      items: 1,
      autoplay: true,
      nav: false,
      autoplayButtonOutput: false,
      controlsText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      responsive: {
        768: {
          items: 2,
        },
        1440: {
          items: 3,
        },
        1700: {
          items: 4,
        },
      },
    });
    slider_produits.events.on("transitionEnd", sliderLazy);
  }
  if (jQuery(".publications__slider").length != 0) {
    var slider_publications = tns({
      container: ".publications__slider",
      items: 1,
      autoplay: true,
      nav: false,
      autoplayButtonOutput: false,
      controlsText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      responsive: {
        768: {
          items: 2,
        },
        1400: {
          items: 3,
        },
        1700: {
          items: 4,
        },
      },
    });
    slider_publications.events.on("transitionEnd", sliderLazy);
  }
}

export function partenairesSlider() {
  var lazyLoad = new LazyLoad();
  function sliderLazy() {
    lazyLoad.update();
  }
  if (jQuery(".sliderInt").length != 0) {
    var slider_int = tns({
      container: ".sliderInt",
      items: 3,
      autoplay: true,
      nav: false,
      autoplayButtonOutput: false,
      autoHeight: true,
      gutter: 20,
      controlsText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      responsive: {
        768: {
          items: 3,
        },
        1280: {
          items: 4,
        },
        1440: {
          items: 4,
        },
      },
    });

    slider_int.events.on("transitionEnd", sliderLazy);
  }
}
