export default function mainMenu() {
  const openButton = jQuery("#menu-open");
  const closeButton = jQuery("#menu-close");
  const menu = jQuery("#main-menu");
  const logo = jQuery(".logo");

  openButton.on("click", function () {
    logo.css("opacity", "0");
    menu.addClass("open");
  });
  closeButton.on("click", function () {
    logo.css("opacity", "1");
    menu.removeClass("open");
  });
  jQuery(".open-sub-menu, .open-sub-menulv2").on("click", function () {
    var siblings = jQuery(this).next();
    siblings.addClass("open");
  });
  jQuery(".precedent").on("click", function () {
    var parent = jQuery(this).parent();
    parent.removeClass("open");
  });

  jQuery(document).on("click", function (e) {
    if (menu.hasClass("open")) {
      if (
        jQuery(e.target).closest("#main-menu").length === 0 &&
        !jQuery(e.target).hasClass("no-close")
      ) {
        logo.css("opacity", "1");
        menu.removeClass("open");
      }
    }
  });
}
